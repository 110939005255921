
export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '50'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'type',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'price',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'tariff_group',
    label: '',
    Object: 'value',
    width: '150'
  }]
}
