<template>
  <div>
     <el-card>
      <search-form
        ref="searchForm"
        :topVisible="true"
        :tabData="tabData"
        labelWidth="120px"
        :searchFormData="searchFormData"
        @search="search" @tabChange="tabChange"
      >
         <template #topBtn>
          <el-button class="marginRight20" type="primary" size="small" @click="handleClick('create')">{{ $t('text.add') }}</el-button>
        </template>
      </search-form>
      <create-table
        ref="createTable"
        :operationColWidth="150"
        :autoWidth="true"
        :operateVisible="operateVisible"
        :loading="loading"
        :btnType="[]"
        :total_count="pageData.total_count ? pageData.total_count : 0"
        :current_page.sync="pageData.page"
        :papeSize.sync="pageData.per_page"
        :topButtonVisible="true"
        :tableData="tableData"
        :tableDataItem="tableDataItem"
        :pageVisible="true"
        :pageData="pageData"
        :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort"
        @handleNewTab="handleNewTab($event, 'storesManagerDetail')"
        @selectionChange="selectionChange"
        @operate="handleClick"
        @forwardToPage="forwardToPage"
      >
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
        </template>
        <template #rightBtn="scope">
          <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <dialogEffect v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import apis from '@/services'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'

export default {
  name: 'Tariffs',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  created () {
    this.queryData(apis.TariffsBillings.getTariffsSetup)
  },
  methods: {
    // 点击操作按钮
    async handleClick (type, data) {
      // console.log(type, data)
      if (type === 'create') {
        this.$router.push({
          name: 'tariffsAdd'
        })
      }
      if (type.name === 'Edit') {
        this.$router.push({
          name: 'tariffsAdd',
          params: {
            id: data.id.value
          }
        })
      }
      if (type.name === 'View') {
        this.$router.push({
          name: 'tariffsDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, apis.TariffsBillings.deleteTariffs)
      }
    },
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      apis.TariffsBillings.getTariffs(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    forwardToPage ({ model_name, id }, name) {
      if (model_name && model_name === 'tariff_group') {
        this.$router.push({ name: 'tariffGroupsDetail', params: { id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
